import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './component/top-bar/top-bar.component';
import { SideMenuComponent } from './component/side-menu/side-menu.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { IconModule } from '@core/icon/icon.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    TopBarComponent,
    SideMenuComponent
  ],
  exports: [
    TopBarComponent,
    SideMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CollapseModule,
    PopoverModule,
    IconModule
  ]
})
export class NavigationModule {
}

import { Component, Input } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RouterNavigation } from '@ngxs/router-plugin';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: [ './top-bar.component.scss' ]
})
export class TopBarComponent {
  public collapsed: boolean = true;

  constructor(private store: Store, private actions: Actions) {
    this.actions.pipe(
      ofActionDispatched(RouterNavigation),
      takeUntilDestroyed()
    ).subscribe(() => this.collapsed = true);
  }

  public toggle(): void {
    const collapsed: boolean = !this.collapsed;
    this.collapsed = collapsed;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@core/layout/component/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('app/view/error/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/view/registration/registration.module').then(m => m.RegistrationModule)
      }
    ]
  },
  { path: '**', redirectTo: 'error/not-found' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}

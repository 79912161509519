import { Component, Input } from '@angular/core';
import { ApplicationModel } from 'app/model/application.model';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MenuItem } from '@data/common/MenuItem';
import { ApplicationSelectors } from '@shared/storage/application/application.selectors';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss' ]
})
export class SideMenuComponent {
  @Select(ApplicationSelectors.menuItems)
  public menuItems$: Observable<MenuItem[]>;

  @Input() public menuCollapse: boolean = false;

  constructor(public readonly applicationModel: ApplicationModel) {
  }
}

import { Component, TemplateRef } from '@angular/core';
import { BasicModal } from '@shared/extender/basic-modal';

export interface ConfirmationModal {
  title: string,

  description: string,

  data?: unknown,

  icon?: string,

  iconClass?: string,

  confirmButtonText?: string,

  cancelButtonText?: string,

  showCancelButton?: boolean
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: [ './confirmation-modal.component.scss' ]
})
export class ConfirmationModalComponent extends BasicModal<boolean> implements ConfirmationModal {

  public title: string;

  public titleTemplate: TemplateRef<any>;

  public description: string;

  public descriptionTemplate: TemplateRef<any>;

  public confirmButtonText: string = 'COMMON.CONFIRM';

  public cancelButtonText: string = 'COMMON.CANCEL';

  public showCancelButton: boolean = true;

  public icon?: string;

  public iconClass?: string;

  public data: any = {};
}

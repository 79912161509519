<div id="layout">
  <div class="container-fluid px-0 position-relative">
    <div class="top-bar">
      <div class="container px-0">
        <app-top-bar></app-top-bar>
      </div>
    </div>
  </div>

  <div class="container px-0">
    <div class="content d-flex">
      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>

</div>



import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-images',
  template: `
      <img src="assets/image/demo_img_1.png" class="me-4">
      <img src="assets/image/demo_img_2.png">
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoImagesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

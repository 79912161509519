import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ApplicationActions } from '@shared/storage/application/application.actions';
import { ApplicationConfig } from '@config/application.config';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LanguageVersion } from '@data/common/LanguageVersion';
import { MenuItem } from '@data/common/MenuItem';

export interface ApplicationStateModel {
  language: LanguageVersion;

  menu: MenuItem[];
}

@State<ApplicationStateModel>({
  name: 'application'
})
@Injectable()
export class ApplicationState implements NgxsOnInit {
  constructor(private translateService: TranslateService,
              private bsLocaleService: BsLocaleService) {
  }

  ngxsOnInit(ctx: StateContext<ApplicationStateModel>) {
    this.setDefaultLanguage(ctx);
  }

  @Action(ApplicationActions.ChangeLanguage)
  public changeLanguage(ctx: StateContext<ApplicationStateModel>, action: ApplicationActions.ChangeLanguage) {
    const { language } = action;

    setTimeout(() => {
      this.translateService.use(language);
      this.bsLocaleService.use(language);
    });

    ctx.patchState({ language });
  }

  @Action(ApplicationActions.UpdateMenu)
  public updateMenu(ctx: StateContext<ApplicationStateModel>, action: ApplicationActions.UpdateMenu) {
    const { menu } = action;

    ctx.patchState({ menu });
  }

  private setDefaultLanguage(ctx: StateContext<ApplicationStateModel>): void {
    this.translateService.setDefaultLang(ApplicationConfig.defaultLanguage);
    const language: LanguageVersion = ctx.getState().language || ApplicationConfig.defaultLanguage;

    ctx.dispatch(new ApplicationActions.ChangeLanguage(language));
  }
}